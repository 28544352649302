<template>
  <div class="profile-board">
    <h2 v-if="loading.results">Загрузка...</h2>
    <h2 v-else-if="!psExists">Тест не завершен</h2>
    <div v-else class="row">
      <div class="col d-flex align-center">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-if="psMain" class="no-caps" href="#your"><b>Ваш психотип</b></v-tab>
          <v-tab v-if="psEasy.length" class="no-caps" href="#easy">
            Психотип, с которым вам<br />легко коммуницировать
          </v-tab>
          <v-tab v-if="psComplex.length" class="no-caps" href="#complex">
            Психотип, с которым вам<br />сложно коммуницировать
          </v-tab>
          <v-tab class="no-caps" href="#all">
            Посмотреть информацию<br />по всем психотипам
          </v-tab>
        </v-tabs>
      </div>
    </div>

    <div v-if="psExists" class="row">
      <div class="col d-flex align-center">
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="psMain" value="your">
            <v-tabs v-model="tabMain" align-with-title>
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab class="no-caps">Основной</v-tab>
              <v-tab v-if="psSecond" class="no-caps">Дополнительный</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabMain">
              <v-tab-item>
                <PsychoReport :ps="psMain" @loadPdf="loadPdf" showPdf @switchTo="switchTo" />
              </v-tab-item>
              <v-tab-item v-if="psSecond">
                <PsychoReport :ps="psSecond"  @loadPdf="loadPdf($event)" @switchTo="switchTo" />
              </v-tab-item>
            </v-tabs-items>
          </v-tab-item>
          <v-tab-item v-if="psEasy.length" value="easy">
            <PsychoTabs :ps_types="psEasy"  @loadPdf="loadPdf($event)" @switchTo="switchTo" />
          </v-tab-item>
          <v-tab-item v-if="psComplex.length" value="complex">
            <PsychoTabs :ps_types="psComplex"  @loadPdf="loadPdf($event)" @switchTo="switchTo" />
          </v-tab-item>
          <v-tab-item value="all">
            <PsychoTabs :ps_types="ps_types"  @loadPdf="loadPdf($event)" :selectTab="selectTabInAll" @switchTo="switchTo" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { mapActions, mapGetters } from "vuex";
import PsychoReport from "@/components/psycho/PsychoReport";
import PsychoTabs from "@/components/psycho/PsychoTabs";
// import ps_types from "@/store/psychotypes.js";
import { CANDIDATE } from "@/common/constants/userTypes";

export default {
  components: {
    PsychoReport,
    PsychoTabs,
  },
  data() {
    return {
      tab: 0,
      tabMain: 0,
      selectTabInAll: ''
    };
  },
  computed: {
    ...mapGetters({
      ps_current: 'users/candidate/resultsPsycho',
      ps_types: 'users/candidate/psychoTypes',
      loading: 'users/candidate/loading',
      user: 'auth/user',
    }),
    psExists() {
        return this.ps_current?.main
    },
    psMain() {
      return this.ps_current?.main ? this.ps_types.find((el) => el.code === this.ps_current.main[0]) : null;
    },
    psSecond() {
      return this.ps_current?.main ? this.ps_types.find((el) => el.code === this.ps_current.second[0]) : null;
    },
    psEasy() {
      return this.psMain?.features_easy
        ? this.ps_types.filter((el) =>
            this.psMain.features_easy.map(el => el.toLowerCase()).includes(el.title.toLowerCase())
          )
        : [];
    },
    psComplex() {
      return this.psMain?.features_complicated
        ? this.ps_types.filter((el) =>
            this.psMain.features_complicated.map(el => el.toLowerCase()).includes(el.title.toLowerCase())
          )
        : [];
    },
    candidateId() {
      return this.user.user_type === CANDIDATE ? this.user.id : this.$route.params.id;
    },  
  },
  methods: {
    ...mapActions({
        loadCandidatePsychoPdf: 'users/candidate/loadCandidatePsychoPdf',
    }),
    switchTo(item) {
      this.selectTabInAll = item
      this.tab = "all"
    },
    async loadPdf(ps, logo) {
      const data = {
        candidate: this.candidateId,
        ps_code: ps.code,
        logo,
      }
      console.log(data);
      const resp = await this.loadCandidatePsychoPdf(data)
      if (resp.link) {
        this.downloadFile(resp.link);
      } else if (resp.error) {
        this.$toast.error(`Ошибка: ${resp.error}`, '', { position: 'topRight' });
      }
    },
    downloadFile(fileLink) {
      const link = document.createElement('a');
      link.setAttribute('download', true);
      link.setAttribute('href', fileLink);
      // link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },

  async mounted() {

  },
};
</script>

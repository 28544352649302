<template>
  <div class="profile-board">
    <h2 v-if="loading.checkList">Загрузка...</h2>
    <h2 v-else-if="!recOriginal">Нет данных</h2>
    <div v-else class="row">
      <div class="col d-flex align-center">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-if="recOriginal" class="no-caps"><b>Оригинальный </b></v-tab>
          <v-tab v-if="recEditable && $store.getters['auth/isAllowed']('view_edited_riskfactor')" class="no-caps">Отредактированный </v-tab>
        </v-tabs>
      </div>
    </div>

    <div v-if="recOriginal" class="row">
      <div class="col d-flex align-center">
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="recOriginal">
            <CheckListReport :recs="recOriginal" @refresh="refresh" @loadPdf="loadPdf('original', $event)" />
          </v-tab-item>
          <v-tab-item>
            <CheckListReport :recs="recEditable" isEditable @refresh="refresh" @editItem="onEdit" @loadPdf="loadPdf('editable', $event)" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <CheckListEditDialog v-model="showEditDialog" :sourceData="editedRec" @save="saveEdited" title="Редактирование чек-листа"/>

  </div>
</template>

<script>
/* eslint-disable */

import { mapActions, mapGetters, mapMutations } from 'vuex';
import CheckListReport from '@/components/recomendations/CheckListReport';
import { CANDIDATE } from '@/common/constants/userTypes';
import CheckListEditDialog from './CheckListEditDialog';

export default {
  components: {
    CheckListReport, CheckListEditDialog
  },
  data() {
    return {
      showEditDialog: false,
      editedRec: null,
      tab: 0,
      tabMain: 0
    };
  },
  computed: {
    ...mapGetters({
      checkList: 'users/candidate/checkList',
      loading: 'users/candidate/loading',
      errors: 'users/candidate/errors',
      user: 'auth/user'
    }),
    recOriginal() {
      return this.checkList?.factors_recommendations?.original ?? null;
    },
    recEditable() {
        return this.checkList?.factors_recommendations?.editable ?? null;
    },
    candidateId() {
      return this.user.user_type === CANDIDATE ? this.user.id : this.$route.params.id;
    }
  },
  methods: {
    ...mapActions({
      loadCandidateCheckList: 'users/candidate/loadCandidateCheckList',
      loadCandidateCheckListPdf: 'users/candidate/loadCandidateCheckListPdf',
      editCheckList: 'users/candidate/editCheckList',
      refreshCandidateCheckList: 'users/candidate/refreshCandidateCheckList',
    }),

    async loadPdf(report_type, logo) {
      const data = {
        candidate: this.candidateId,
        report_type,
        target: 1,
        logo
      };
      const resp = await this.loadCandidateCheckListPdf(data);
      if (resp.link) {
        this.downloadFile(resp.link);
      } else if (resp.error) {
        this.$toast.error(`Ошибка: ${resp.error}`, '', { position: 'topRight' });
      }
    },
    async refresh() {
      const response = await this.refreshCandidateCheckList({
        candidate: this.candidateId,
        target: 1
      });
      if (!response) {
        console.log(this.errors);
        if(this.errors?.checkList?.detail)
          this.$toast.error(this.errors.checkList.detail, '', {position: 'topRight'})
      }
    },
    downloadFile(fileLink) {
      const link = document.createElement('a');
      link.setAttribute('download', true);
      link.setAttribute('href', fileLink);
      // link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onEdit(rec) {
      this.editedRec = rec;
      this.showEditDialog = true;
    },
    saveEdited(rec) {
      const response  = this.editCheckList({
        id: this.editedRec.id,
        title: rec.title,
        text: rec.text.join("\n")
      })
      if (response) {
        this.showEditDialog = false;
        this.loadCandidateCheckList( {
          candidate: this.candidateId,
          target: 1
        }) 
      }
    }

  },

  async mounted() {
    await this.loadCandidateCheckList( {
        candidate: this.candidateId,
        target: 1
    }) 

  }
};
</script>
  